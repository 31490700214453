<template>
  <div class="activity">
    <!--写跟进-->
    <el-button
      v-if="isSubmit"
      size="mini"
      icon="el-icon-circle-plus"
      @click="openFold()"
    >写跟进</el-button>
    <!--输入框-->
    <div
      v-if="isSubmit"
      class="activity-input mt10 rel"
      :class="isFold ? 'fold' : 'unfold'"
    >
      <i v-if="isFold" class="input-icon el-icon-edit-outline" />
      <div v-if="!isFold " class="input-top row">
        <!-- <div class="row fcs" >
          <el-select
            v-model="detail.content_type"
            size="mini"
            class="w100 mr20"
            @change="changeSelect"
          >
            <el-option
              v-for="(item, index) in options.type"
              :key="index"
              :label="item.label"
              :value="item.id"
            />
          </el-select>
          <el-select
            v-if="
              handleType === 'order' &&
                (detail.content_type === 4 || detail.content_type === 5)
            "
            v-model="detail.is_confirm"
            size="mini"
            class="w100"
            placeholder="是否确认"
          >
            <el-option label="未确认" :value="0" />
            <el-option label="已确认" :value="1" />
          </el-select>
          <el-select
            v-if="handleType === 'order' && detail.content_type === 3"
            v-model="detail.is_confirm"
            size="mini"
            class="w100"
            placeholder="是否支付"
          >
            <el-option label="未支付" :value="0" />
            <el-option label="已支付" :value="1" />
          </el-select>
          <el-select
            v-if="handleType === 'customer' && detail.content_type === 7"
            v-model="detail.sample_id"
            size="mini"
            class="w140 mr20"
            placeholder="请选择试样商品"
            @change="changeGoods"
          >
            <el-option
              v-for="(item, index) in goodsList"
              :key="index"
              :label="item.goods_name"
              :value="item.sample_id"
            />
          </el-select>
          <el-input
            v-if="handleType === 'customer' && detail.content_type === 7"
            v-model="detail.quantity"
            size="mini"
            class="w140"
            placeholder="请输入试样数量"
          />
          <span
            v-if="handleType === 'customer' && detail.content_type === 7"
            class="fz12 ml20"
          >剩余数量：{{ goodsNum }}{{ sample_unit }}</span>
        </div> -->
        <i class="el-icon-circle-close fz20 hand" @click="closeFold()" />
      </div>
      <el-input
        ref="textarea"
        :key="Number(isFold)"
        v-model="detail.content"
        type="textarea"
        show-word-limit
        maxlength="200"
        resize="none"
        :autosize="autosize"
        size="small"
        placeholder="请输入跟进内容"
        class="input-content"
        :class="display ? 'show' : 'hide'"
        @focus="openFold()"
      />
      <div v-if="!isFold" class="input-bottom">
        <img-upload
          v-show="!isFold && display"
          ref="uploader"
		  :limit-num="9"
          @setUrl="getImgUrl"
        />
        <div class="bottom-btn">
          <div class=" fz16 lh20" @click="handleUpload()">
            <i class="el-icon-picture-outline fz18" />图片
          </div>
          <el-button
            class="block"
            size="mini"
            type="primary"
            @click="release()"
          >发布</el-button>
        </div>
      </div>
    </div>
    <!--记录-->
    <div
      v-if="isShowList"
      class="activity-list mt20"
      :class="isFold ? 'fold' : 'unfold'"
    >
      <div
        v-for="(item, index) in activityList"
        :key="index"
        class="list-item row fcs rel"
      >
        <div class="item-circle mr30" />
        <div class="item-line" />
        <div class="fz14">
          <div class="mb5 fwb">{{ item.create_user_name }}</div>
          <div class="fz12 c666 mb5 row">
            <div>
              <span>{{ item.create_time }}</span>
              <span class="item-tag c333 ml25">{{
                item.content_type_name
              }}</span>
              <span
                v-if="item.follow_type === 4 && item.content_type === 3"
                class="item-tag c333 ml25"
              >{{ item.is_confirm == 0 ? "未支付" : "已支付" }}</span>
              <span
                v-if="item.follow_type === 4 && item.content_type === 4"
                class="item-tag c333 ml25"
              >{{ item.is_confirm == 0 ? "未确定" : "已确定" }}</span>
              <span
                v-if="item.follow_type === 4 && item.content_type === 5"
                class="item-tag c333 ml25"
              >{{ item.is_confirm == 0 ? "未确定" : "已确定" }}</span>
            </div>
            <div v-if="item.follow_type === 1">线 索 - 跟进记录</div>
            <div v-if="item.follow_type === 2">客 户 - 跟进记录</div>
            <div v-if="item.follow_type === 3">商 机 - 跟进记录</div>
            <div v-if="item.follow_type === 4">订 单 - 跟进记录</div>
            <div v-if="item.follow_type === 5">小程序 - 跟进记录</div>
          </div>
          <div class="item-content mb15">{{ item.content }}</div>
          <div
            v-if="item.follow_type === 2 && item.content_type === 7"
            class="mb15"
          >
            <span>试样商品:</span>
            <span class="item-tag c333 ml25">{{ item.goods_name }}</span>
          </div>
          <div v-if="item.follow_type === 2 && item.content_type === 7">
            <span>试样数量:</span>
            <span class="item-tag c333 ml25 mb15">{{ item.sample_quantity }}</span>
          </div>
          <div class="item-content mb15">
            <div class="imgBox">
              <div class="itemImg ml20" v-for="(url, key) in item.imageList" :key="key">
                <el-image :src="url" :preview-src-list="item.imageList"></el-image>
                <i v-if="userInfo.id == 548 || userInfo.id == 777 || userInfo.id == 875 || userInfo.id == 1150 || userInfo.id == 444" class="el-icon-circle-close" @click="clickDelImg(item.id,key)"></i>
              </div>
            </div>
            <!-- <el-image
              v-for="(url, key) in item.imageList"
              :key="key"
              :src="url"
              :preview-src-list="item.imageList"
              class="content-img mr10"
            /> -->
          </div>
        </div>
      </div>
      <div class="fz12 tc">没有更多了</div>
    </div>
  </div>
</template>

<script>
class Options {
  constructor(type) {
    switch (type) {
      case 'leads':
        this.type = [
          { id: 1, label: '打电话' },
          { id: 2, label: '实地考察' },
          { id: 3, label: '其他' }
        ];
        break;
      case 'customer':
        this.type = [
          { id: 1, label: '打电话' },
          { id: 2, label: '实地考察' },
          { id: 3, label: '合作约谈' },
          { id: 4, label: '订单交易' },
          { id: 5, label: '客户反馈' },
          { id: 7, label: '试样' },
          { id: 6, label: '其他' }
        ];
        break;
      case 'business':
        this.type = [
          { id: 1, label: '打电话' },
          { id: 2, label: '合作约谈' },
          // { id: 3, label: '试样' },
          { id: 4, label: '方案报价' },
          { id: 5, label: '客户反馈' },
          { id: 6, label: '其他' }
        ];
        break;
      case 'apple':
        this.type = [
          { id: 1, label: '打电话' },
          { id: 2, label: '合作约谈' },
          { id: 3, label: '客户反馈' },
          { id: 4, label: '其他' }
        ];
        break;
      case 'order':
        this.type = [
          { id: 1, label: '联系客户' },
          { id: 2, label: '客户反馈' },
          { id: 3, label: '支付确认' },
          { id: 7, label: '支付凭证' },
          { id: 5, label: '业务员确认' },
          { id: 6, label: '其他' }
        ];
        break;
      case 'mini':
        this.type = [
          { id: 1, label: '打电话' },
          { id: 2, label: '合作约谈' },
          { id: 3, label: '客户反馈' },
          { id: 4, label: '其他' }
        ];
        break;
    }
  }
}
class Detail {
  content_type = 1; // 跟进方式
  content = ''; // 跟进内容
  is_confirm = 0; // 是否确认（支付）
  sample_id = ''; // 式样id
  quantity = ''; // 式样数量
  file_path = []; // 图片路径集合
}
import imgUpload from '@/components/Upload/imgUpload';
import { sampleGoods,caseDelImg } from '@/api/client';
import { mapGetters } from 'vuex';
export default {
  name: 'Activity',
  components: {
    imgUpload
  },
  props: {
    handleType: {
      type: String,
      default: 'client'
    },
    activityList: {
      type: Array,
      default: () => []
    },
    orderDetail: {
      type: Object,
      default() {
        return {};
      }
    },
    isSubmit: {
      type: Boolean,
      default: true
    },
    isShowList: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isFold: true,
      detail: new Detail(),
      options: new Options(this.handleType),
      goodsList: [],
      goodsNum: 0,
      sample_unit: ''
    };
  },
  computed: {
    ...mapGetters(['pageLimits']),
    ...mapGetters(['userInfo']),
    autosize() {
      return this.isFold
        ? { minRows: 1, maxRows: 1 }
        : { minRows: 3, maxRows: 6 };
    },
    display() {
      return this.detail.file_path.length > 0;
    }
  },
  watch: {
    activityList(val) {
      this.detail.content_type = 1;
    }
  },
  mounted() {
    console.log(this.activityList)
  },
  methods: {
    // 改变下拉值
    async changeSelect(e) {
      if (e == 7 && this.handleType === 'customer') {
        const { data } = await sampleGoods();
        this.goodsList = data;
      }
    },
    // 改变试样商品
    changeGoods(e) {
      // console.log(e);
      this.goodsList.forEach((item) => {
        if (item.sample_id == e) {
          this.goodsNum = item.quantity;
          this.sample_unit = item.sample_unit;
        }
      });
    },
    // 打开折叠
    openFold() {
      this.isFold = false;
      this.$nextTick(() => {
        this.$refs.textarea.focus();
      });
    },
    // 关闭折叠
    closeFold() {
      this.isFold = true;
    },
    // 提交图片
    handleUpload() {
      this.$refs.uploader.goToUpload();
    },
    // 图片
    getImgUrl({ url }) {
      // console.log(url)
      // this.detail.file_path = url.map((val) => val.relativeUrl);
      this.detail.file_path = url.map((val) => val.absoluteUrl);
    },
    // 发布跟进
    release() {
      if (
        this.handleType === 'business' &&
        this.detail.content_type === 3 &&
        !this.detail.sample_id
      ) {
        return this.$notification('提交失败', 'warning', '式样商品未填写');
      }
      if (
        this.handleType === 'business' &&
        this.detail.content_type === 3 &&
        !this.detail.quantity
      ) {
        return this.$notification('提交失败', 'warning', '式样商品数量未填写');
      }
      let reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
      if (this.detail.quantity && !reg.test(this.detail.quantity)) {
        return this.$notification(
          '提交失败',
          'warning',
          '式样商品数量最多保留两位小数'
        );
      }
      if (!this.detail.content) {
        return this.$notification('提交失败', 'warning', '跟进内容未填写');
      }
      if (
        this.handleType === 'order' &&
        this.detail.content_type === 3 &&
        this.detail.content_type === 4 &&
        this.detail.content_type === 5 &&
        this.detail.is_confirm === ''
      ) {
        return this.$notification(
          '提交失败',
          'warning',
          '请填写是否确认或支付'
        );
      }
      // console.log(this.orderDetail);
      this.$emit('followAdd', this.detail);
      this.$refs.uploader.handleClear();
      this.detail = new Detail();
    },

    // 删除图片
    clickDelImg(e,k){
      // console.log(e);
      // console.log(k);
      caseDelImg({ id: e,key:k }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        location.reload();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.imgBox {
  max-width: 85%;
  display: flex;
  flex-wrap: wrap;
}
.itemImg {
  position: relative;
}
.itemImg .el-image {
  width: 120px;
  height: 96px;
  margin-bottom: 10px;
  border-radius: 6px;
}
.itemImg .el-icon-circle-close:before {
  font-size: 16px;
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 66;
  cursor: pointer;
  color: #999;
  background: rgba(255, 255, 255, 0.664);
  border-radius: 50%;
}
.hand{
     position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
  }
  .hand:hover {
        color: #2362fb;
      }
.activity-input {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  position: relative;
  
  &:hover {
    .input-icon {
      color: #2362fb;
    }
  }
  &.fold {
    ::v-deep.el-textarea__inner {
      cursor: pointer;
      padding-left: 30px;
    }
  }
  &.unfold {
    padding: 10px;
    ::v-deep.el-textarea__inner {
      padding: 5px 0;
    }
  }
  .input-icon {
    color: #999;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
  }
  .el-input {
    width: 140px;
  }
  .input-content {
    display: block;
    ::v-deep.el-textarea__inner {
      border: none;
    }
    &.show ::v-deep .el-input__count {
      bottom: -140px;
      right: 70px;
    }
    &.hide ::v-deep .el-input__count {
      bottom: -28px;
      right: 70px;
    }
  }
  .input-bottom {
    .bottom-btn {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      
    }
  }
}
.activity-list {
  overflow-y: auto;
  max-height: 260px;
  .list-item {
    align-items: stretch;
    line-height: 18px;
    .item-circle {
      width: 18px;
      height: 18px;
      z-index: 2;
      border-radius: 9px;
      background-color: white;
      border: 5px solid #a5ecd7;
    }
    .item-line {
      position: absolute;
      width: 1px;
      top: 3px;
      left: 9px;
      bottom: -3px;
      z-index: 1;
      // background: #e6e6e6;
    }
    .item-tag {
      display: inline-block;
      padding: 2px 5px;
      background: #f6f6f6;
      border-radius: 3px;
    }
    .item-content {
      width: 800px;
      .content-img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>
